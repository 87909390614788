<template>
    <div id="app">
        <div class="box-item">
            <nut-row>
                <nut-col style="width: 100%">
                    <div style="margin-bottom: 25px">
                        <img src="img/newlogo2.png" width="35%">
                        <img src="img/introduce/introduce0.png" style="width:40%; float:right">
                    </div>
                    <div>
                        <p class="paragraph">截至2020年，全国独居老人和空巢老人约1.18亿，其中独居老人3000万。老人日常生活情况如何？有没有遇到突发状况？这是摆在家属面前的难题！</p>
                    </div>
                    <img src="img/introduce/introduce1.png" align="center" width="100%">
                    <br/>
                    <div style="margin-top: 5%" align="center">
                        <img src="img/introduce/title1.png" width="70%">
                    </div>
                    <div>
                        <p class="paragraph">通过<b class="red-text">行为传感器和智能化设备</b>采集老人的活动轨迹，运用人工智能和大数据分析技术，对老人的睡眠、三餐、外出和二便等日常生活行为进行分析，建立老人生活数据模型，让家属<b class="red-text">及时掌握老人的生活情况</b>，出现疑似异常情况时进行提醒。</p>
                    </div>
                    <img src="img/introduce/introduce2.png" align="center" width="100%">
                    <br/>
                    <div style="margin-top: 5%" align="center">
                        <img src="img/introduce/title2.png" width="70%">
                    </div>
                    <br/>
                    <img src="img/introduce/introduce3.png" align="center" width="100%">
                    <br/>
                    <div style="margin-top: 5%" align="center">
                        <img src="img/introduce/title3.png" width="70%">
                    </div>
                    <p class="paragraph">价格亲民、无需施工的家用型产品。</p>
                    <img src="img/introduce/introduce4.png" align="center" width="100%">
                    <br/>
                    <div style="margin-top: 5%" align="center">
                        <img src="img/introduce/title4.png" width="70%">
                    </div>
                    <br/>
                    <img src="img/introduce/introduce5.png" align="center" width="100%">
                    <p class="paragraph">除上述套装外，平台还提供紧急呼叫器、烟雾报警器、水浸报警器等智能化设备，您可根据自身的需求进行定制。</p>
                    <div style="margin-top: 5%" align="center">
                        <img src="img/introduce/title5.png" width="70%">
                    </div>
                    <div class="paragraph_huise">
                        <p>@老人一个人居住</p>
                        <p>@老人和保姆一起住</p>
                        <p>@老人和子女一起住，但白天一个人在家</p>
                        <p>@老两口自己居住</p>
                    </div>
                    <div>
                        <p class="paragraph">平台本着“以人为本”的理念，承载居家养老的守护使命，为中国亿万老人提供一个安全、放心的居家养老环境。</p>
                    </div>
                </nut-col>
            </nut-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Introduce"
    }
</script>

<style scoped>

</style>